//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import icon from '~/components/GlobalUI/g-icon'
import Breadcrumbs from '~/components/UI/Breadcrumbs.vue'
import { SET_BC } from '~/store/bc/types.bc'

export default {
  name: 'ArticlePage',
  components: {
    Breadcrumbs,
    icon,
  },
  data() {
    return {
      article: {},
    }
  },

  mounted() {
    this.fetchArticle()
  },

  methods: {
    fetchArticle() {
      const slug = this.$route.params.slug
      return this.$request.$get(`/blogs/${slug}`).then(({ data }) => {
        const { blog, previous, next } = data
        const blogUrl = this.$route.path.split('/')[1]
        this.$store.commit(`bc/${SET_BC}`, [
          { name: 'Blog', slug: blogUrl },
          { name: blog.name, slug: blog.slug },
        ])

        this.article = {
          ...blog,
          previous,
          next,
          fileUrl: `${this.$config.baseURL}/files${blog.file_url}`,
        }
      })
    },
  },
}
