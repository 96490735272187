//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Breadcrumbs from '~/components/UI/Breadcrumbs.vue'

export default {
  name: 'StaticPage',
  components: {
    Breadcrumbs,
  },
  async asyncData({  $api, store, params, localePath, error }) {
    try {
      const response = await $api.main.getStatic(params.slug)
      store.commit('bc/SET_BC', [
        { name: response.data.name, slug: localePath({ name: response.data.name }) },
      ])
      return {
        page: response.data,
      }
    } catch (e) {
      error({ statusCode: 404 })
    }
  },
  data() {
    return {
      page: {},
    }
  },
}
