//
//
//
//
//
//
//
//
//
//

import Header from '~/components/layouts/Header/Header.vue'
import setDeviceType from '~/mixins/setDeviceType'
export default {
  components: {
    Header,
    Footer: () => import('~/components/layouts/Footer/Footer.vue'),
  },
  mixins: [setDeviceType],
}
