//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Header from '~/components/layouts/Header/Header.vue'
import NavSide from '~/components/pages/Profile/NavSide.vue'
import setDeviceType from '~/mixins/setDeviceType'
import { FETCH_USER } from '~/store/types.main'
export default {
  components: {
    Header,
    NavSide,
  },
  mixins: [setDeviceType],
  data() {
    return {
      active: false,
    }
  },
  mounted() {
    this.fetchUser()
  },
  methods: {
    fetchUser() {
      return this.$store.dispatch(`user/${FETCH_USER}`)
    },
    onOpen() {
      this.active = !this.active
    },
    onClose() {
      this.active = false
    },
  },
}
