//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  fetch() {
    const payload = {
      order_number: this.$route.query.order,
    }
    this.$api.cart.cartCheckoutSuccess(payload)
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
  },
}
