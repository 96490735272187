//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Breadcrumbs from '~/components/UI/Breadcrumbs.vue'
import Paginations from '~/components/UI/Paginations.vue'
import { HAS_TOKEN } from '~/store/auth/types.auth'
import { SET_BC } from '~/store/bc/types.bc'
export default {
  name: 'Blog',
  components: {
    Breadcrumbs,
    Paginations,
  },
  data() {
    return {
      articles: [],
      meta: [],
      fileUrl: `${this.$config.baseURL}/files`,
      maxLength: 150,
      total: 0,
      pages: 0,
      sort: {
        orderBy: {},
        per_page: 30,
        page: 1,
      },
      selectPage: 1,
      countItems: 0,
    }
  },
  computed: {
    isAuth() {
      return this.$store.getters[`auth/${HAS_TOKEN}`]
    },

    currentPage() {
      const page = this.$route.query.page
      return parseInt(page) || 1
    },
  },

  watch: {
    currentPage(val) {
      this.fetchBlogList(val)
    },
  },
  created() {
    this.fetchBlogList()
  },
  methods: {
    fetchBlogList(value) {
      return this.$request.$get(`blogs?page=${value}`).then(({ data, meta }) => {
        this.$store.commit(`bc/${SET_BC}`, [
          { name: 'Blog', slug: this.localePath({ name: 'Blog' }) },
        ])
        this.articles = data
        this.total = meta.pagination.total
        this.pages = meta.pagination.total_pages
        this.countItems = meta.pagination.count
      })
    },

    selectOffset(value) {
      this.sort.per_page = value
    },

    onSelectPage(value) {
      this.$router.push({name: this.$route.name, query: {page: value}})
    },
  },
}
