//
//
//
//
//
//
//
//
//
//
//

export default {
  layout: 'landing',
  props: ['error'],
}
