//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FiltersLayout from '~/components/layouts/Filters/FiltersLayout.vue'
import ProductItemCard from '~/components/modules/Cards/ProductItemCard.vue'
import Breadcrumbs from '~/components/UI/Breadcrumbs.vue'
import { SET_BC } from '~/store/bc/types.bc'
import { categories } from '~/constants/filters.const'
import { getQuery, setQuery } from '@/helpers/query.helper'
import isEqualObjects from '@/helpers/isEqualObjects.helper'
export default {
  name: 'CatalogPage',
  components: {
    FiltersLayout,
    ProductItemCard,
    Breadcrumbs,
  },
  data() {
    return {
      items: [],
      params: {
        categories: [],
        attributes: {},
        orderBy: {
          id: 'DESC',
        },
        per_page: 10,
        page: 1,
      },
      filters: {
        attributes: {},
        categories: {
          name: 'Category',
          items: categories,
        },
      },

      sort: [
        {
          name: 'Price (low to high)',
          id: 'ASC',
        },
        {
          name: 'Price (high to low)',
          id: 'DESC',
        },
      ],
    }
  },
  fetch() {
    this.$store.commit(`bc/${SET_BC}`, [
      {
        name: 'Catalog',
        slug: this.localePath({ name: 'catalog' }),
      },
      {
        name: this.slug,
        slug: this.localePath({
          name: 'catalog-query',
          params: { query: this.slug },
        }),
      },
    ])
    this.setDefaultFilters()
    return Promise.all([this.fetchAttributes()])
  },
  computed: {
    slug() {
      return this.$route && this.$route.params && this.$route.params.query
        ? this.$route.params.query
        : ''
    },
  },

  methods: {
    fetchItems() {
      const slug = this.slug ? `/${this.slug}` : ''
      return this.$request
        .$get(`catalog${slug}`, this.params)
        .then(({ data, meta }) => {
          this.items = data.map((item, i) => ({
            ...item,
            id: i,
          }))
        })
    },
    fetchAttributes() {
      return this.$request.$get('attributes').then(({ data }) => {
        this.filters.attributes = {}
        const keys = Object.keys(data).slice(0, 3)
        for (const key of keys) {
          this.filters.attributes[key] = data[key]
        }
      })
    },
    onClickItem(value) {
      const { id, data } = value
      const keys = ['labels', 'status', 'name', 'price_type', 'slug']
      const slide = this.items.find((item) => item.id === id)
      for (const key of keys) {
        slide[key] = data[key]
      }
      const variations = Object.keys(data.variations).filter(
        (key) => key !== 'primary',
      )
      slide.variations = data.variations[
        data.variations.primary ? data.variations.primary : variations[0]
      ].map((variation) => ({
        ...variation,
        file: variation.files[0]?.preview_url ? variation.files[0].preview_url : '',
      }))
    },
    onSelectFilter(key, item) {
      if (this.params.attributes[key]) {
        const index = this.params.attributes[key].findIndex(
          (attr) => attr === item,
        )
        if (index !== -1) {
          this.params.attributes[key].splice(index, 1)
        } else {
          this.params.attributes[key].push(item)
        }
      } else {
        this.params.attributes[key] = []
        this.params.attributes[key].push(item)
      }
      this.changeQueryFilters()
    },
    onSelectAllOptions(key) {
      if (this.params.attributes[key]) {
        const arrayLength = this.params.attributes[key].length
        if (
          !arrayLength ||
          (arrayLength && arrayLength < this.filters.attributes[key].length)
        ) {
          this.params.attributes[key] = [...this.filters.attributes[key]]
        } else if (arrayLength === this.filters.attributes[key].length) {
          this.params.attributes[key] = []
        }
      } else {
        this.params.attributes[key] = [...this.filters.attributes[key]]
      }
      this.changeQueryFilters()
    },
    changeQueryFilters() {
      const query = {}
      for (const key in this.params.attributes) {
        if (Object.hasOwnProperty.call(this.params.attributes, key)) {
          const items = this.params.attributes[key]
          query[key] = [...items]
        }
      }
      if (isEqualObjects(getQuery(this.$route.query), query)) return
      // this.fetchItems()

      this.$router.replace({
        path: this.$route.path,
        query: setQuery(query),
      })
    },
    changeQuery() {
      const query = {}
      for (const key in this.filters) {
        if (Object.hasOwnProperty.call(this.filters, key)) {
          const element = this.filters[key]
          const selected = element.items
            .filter((item) => item.selected === true)
            .map((item) => item.name.toLowerCase())
          query[key] = [...selected]
          this.params[key] = selected
        }
      }
      if (isEqualObjects(getQuery(this.$route.query), query)) return
      this.fetchItems()

      this.$router.replace({
        path: this.$route.path,
        query: setQuery(query),
      })
    },
    setDefaultFilters() {
      const query = getQuery(this.$route.query)
      this.params.attributes = {}
      Object.entries(query).forEach(([key, value]) => {
        if (
          key !== 'categories' &&
          key !== 'price' &&
          key !== 'orderBy' &&
          key !== 'per_page' &&
          key !== 'page'
        ) {
          const name = key.replace(/\+/g, ' ')
          this.params.attributes[name] = value
        }
      })
    },
  },
}
